import { LinearProgress, Typography } from "@material-ui/core";

const ProgressOverlay = ({ progress }) => {
  return (
    <div>
      <div style={{ position: "absolute", width: "100%", height: "100%" }}>
        <LinearProgress variant="determinate" value={progress * 100} />
      </div>
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
          display: "flex",
          position: "absolute",
        }}
      >
        <Typography variant="h6" gutterBottom>
          Ludenso Studio is currently loading...
        </Typography>
      </div>
    </div>
  );
};

export default ProgressOverlay;
