import { DropzoneDialog } from "material-ui-dropzone";
import { useEffect, useState } from "react";

const ThumbnailDropzone = ({ unityContext }) => {
  const [open, setOpen] = useState(false);
  const [unityGameObjectName, setUnityGameObjectName] = useState("");
  const [unityFunctionName, setUnityFunctionName] = useState("");
  const [acceptedFiles, setAcceptedFiles] = useState([]);

  useEffect(() => {
    unityContext.on(
      "uploadThumbnail",
      (gameObjectName, functionName, acceptedFileEnding) => {
        setUnityGameObjectName(gameObjectName);
        setUnityFunctionName(functionName);
        setAcceptedFiles([acceptedFileEnding]);
        setOpen(true);
      }
    );
  });

  const handleSave = (files) => {
    var firstFile = files[0];
    document.uploadedFileStorage = {
      loadedFiles: {},
      dataPointers: [],
    };

    var reader = new FileReader();
    reader.onload = ((file) => (fileloadEvent) => {
      let loadedFileInfo = fileloadEvent.target.result;

      document.uploadedFileStorage.loadedFiles[file.name] = {
        info: loadedFileInfo,
      };

      let extensionSplit = file.name.split(".");
      let extension = extensionSplit[extensionSplit.length - 1];
      let name = file.name.replace(extension, "");
      name = name.substring(0, name.length - 1);

      let loadedFile = {
        fullName: file.name,
        name: name,
        path: "unavailable-in-web",
        length: loadedFileInfo.byteLength,
        size: file.size,
        extension: extension,
      };

      unityContext.send(
        unityGameObjectName,
        unityFunctionName,
        JSON.stringify(loadedFile)
      );
    })(firstFile);

    reader.readAsArrayBuffer(firstFile);
    setOpen(false);
  };

  return (
    <DropzoneDialog
      open={open}
      onSave={handleSave}
      acceptedFiles={acceptedFiles}
      showPreviews={true}
      maxFileSize={5000000000}
      onClose={() => setOpen(false)}
      filesLimit={1}
    />
  );
};

export default ThumbnailDropzone;
