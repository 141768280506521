import { LogLevel } from "@azure/msal-browser";

export const b2cPolicies = {
    names: {
        signUpSignIn: "B2C_1_SIGNIN",
        forgotPassword: "b2c_1_RESET",
        editProfile: "b2c_1_edit_profile"
    },
    authorities: {
        signUpSignIn: {
            authority: "https://ludensoarplatformprod.b2clogin.com/ludensoarplatformprod.onmicrosoft.com/B2C_1_SIGNIN",
        },
        forgotPassword: {
            authority: "https://ludensoarplatformprod.b2clogin.com/ludensoarplatformprod.onmicrosoft.com/b2c_1_RESET",
        },
        editProfile: {
            authority: "https://ludensoarplatformprod.b2clogin.com/ludensoarplatformprod.onmicrosoft.com/b2c_1_edit_profile"
        }
    },
    authorityDomain: "ludensoarplatformprod.b2clogin.com"
}

export const msalConfig = {
    auth: {
        clientId: "3e4a8fd9-9a1b-4da9-871f-35d30d8b983f",
        authority: b2cPolicies.authorities.signUpSignIn.authority, 
        knownAuthorities: [b2cPolicies.authorityDomain],
        redirectUri: "/",
        postLogoutRedirectUri: "/",
        navigateToLoginRequestUrl: false,
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            }
        }
    }
};

export const protectedResources = {
    api: {
        endpoint: "https://app-ludensoarplatform-prod-001.azurewebsites.net/api/",
        scopes: ["https://ludensoarplatformprod.onmicrosoft.com/19ed9294-ebaf-42e6-ab4d-6585dbce3fc4/Publisher.Edit"]
    },
}

 export const loginRequest = {
    scopes: [...protectedResources.api.scopes]
};
