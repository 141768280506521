import { DropzoneDialog } from "material-ui-dropzone";
import { useEffect, useState } from "react";
import { protectedResources } from "../authConfig";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";

const chunkSize = 4194304;

const Dropzone = ({ unityContext, instance, setIsUploading }) => {
  const [open, setOpen] = useState(false);
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [unityGameObjectName, setUnityGameObjectName] = useState("");
  const [unityFunctionName, setUnityFunctionName] = useState("");
  const [progress, setProgress] = useState(0);
  const [counter, setCounter] = useState(1);
  const [fileToBeUpload, setFileToBeUpload] = useState({});
  const [beginingOfTheChunk, setBeginingOfTheChunk] = useState(0);
  const [endOfTheChunk, setEndOfTheChunk] = useState(chunkSize);
  const [fileGuid, setFileGuid] = useState("");
  const [fileSize, setFileSize] = useState(0);
  const [chunkCount, setChunkCount] = useState(0);
  const [accessToken, setAccessToken] = useState("");

  useEffect(() => {
    unityContext.on(
      "uploadFile",
      (gameObjectName, functionName, acceptedFileEnding) => {
        setUnityGameObjectName(gameObjectName);
        setUnityFunctionName(functionName);
        setAcceptedFiles([acceptedFileEnding]);
        setOpen(true);
      }
    );
  });

  const handleSave = (files) => {
    var firstFile = files[0];
    document.uploadedFileStorage = {
      loadedFiles: {},
      dataPointers: [],
    };

    var reader = new FileReader();
    reader.onload = (() => (_) => {
      instance
        .acquireTokenSilent({
          scopes: protectedResources.api.scopes,
          account: instance.getAllAccounts()[0],
        })
        .then((response) => {
          setAccessToken(response.accessToken);
          const _fileID = uuidv4();
          setFileGuid(_fileID);
          setCounter(1);
          setBeginingOfTheChunk(0);
          setEndOfTheChunk(chunkSize);
          const _file = firstFile;
          const _totalCount =
            _file.size % chunkSize == 0
              ? _file.size / chunkSize
              : Math.floor(_file.size / chunkSize) + 1;
          setChunkCount(_totalCount);
          setProgress(0);
          setFileSize(_file.size);
          setFileToBeUpload(_file);
        });
    })(firstFile);

    reader.readAsArrayBuffer(firstFile);
    setIsUploading(true);
    setOpen(false);
  };

  useEffect(() => {
    if (fileSize > 0) {
      fileUpload(counter);
    }
  }, [fileToBeUpload, progress]);

  const fileUpload = () => {
    setCounter(counter + 1);
    if (counter <= chunkCount) {
      var chunk = fileToBeUpload.slice(beginingOfTheChunk, endOfTheChunk);
      uploadChunk(chunk);
    }
  };

  const uploadChunk = async (chunk) => {
    try {
      const response = await axios.post(
        protectedResources.api.endpoint + "CreatePdf/UploadChunks",
        chunk,
        {
          params: {
            chunkId: counter,
            uid: fileGuid,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      const data = response.data;
      if (!data.hasError) {
        setBeginingOfTheChunk(endOfTheChunk);
        setEndOfTheChunk(endOfTheChunk + chunkSize);
        if (counter == chunkCount) {
          console.log("Process is complete, counter", counter);
          await uploadCompleted();
        } else {
          var percentage = (counter / chunkCount) * 100;
          setProgress(percentage);
        }
      } else {
        console.log("Error Occurred:", data.errorMessage);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const uploadCompleted = async () => {
    var formData = new FormData();
    formData.append("fileName", fileGuid);

    const response = await axios.post(
      protectedResources.api.endpoint + "/CreatePdf/UploadComplete",
      {},
      {
        params: {
          uid: fileGuid,
          chunkCount: chunkCount,
        },
        headers: {
          Authorization: "Bearer " + accessToken,
        },
        data: formData,
      }
    );

    const data = response.data;
    if (!data.hasError) {
      setProgress(100);
      unityContext.send(
        unityGameObjectName,
        unityFunctionName,
        JSON.stringify(data.response)
      );
    } else {
      console.log("Error Occurred:", data.errorMessage);
    }

    setFileSize(0);
    setIsUploading(false);
  };

  return (
    <DropzoneDialog
      open={open}
      onSave={handleSave}
      acceptedFiles={acceptedFiles}
      showPreviews={true}
      maxFileSize={1000000000}
      onClose={() => setOpen(false)}
      filesLimit={1}
    />
  );
};

export default Dropzone;
