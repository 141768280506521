import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { loginRequest, protectedResources } from "./authConfig";
import "./styles/App.css";
import Unity, { UnityContext } from "react-unity-webgl";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import Dropzone from "./components/Dropzone";
import ThumbnailDropzone from "./components/ThumbnailDropzone";
import VideoDropzone from "./components/VideoDropzone";
import ProgressOverlay from "./components/ProgressOverlay";
import Spinner from "./components/Spinner";

const unityContext = new UnityContext({
  loaderUrl: "WebGLBuild.loader.js",
  dataUrl: "WebGLBuild.data.unityweb",
  frameworkUrl: "WebGLBuild.framework.js.unityweb",
  codeUrl: "WebGLBuild.wasm.unityweb",
});

const App = ({ instance }) => {
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    unityContext.on("refreshJwt", (gameObjectName, functionName) =>
      instance
        .acquireTokenSilent({
          scopes: protectedResources.api.scopes,
          account: instance.getAllAccounts()[0],
        })
        .then((response) =>
          unityContext.send(gameObjectName, functionName, response.accessToken)
        ).catch((error) => {
          console.Log("Failed to renew token " + error)
          instance.acquireTokenRedirect(loginRequest);
        })
    );

    unityContext.on("copyToClipboard", (value) => {
      navigator.clipboard.writeText(value);
    });
    unityContext.on("progress", function (progression) {
      setProgress(progression);
    });
  });

  const handleKeyDown = (event) => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === "v") {
      navigator.clipboard.readText().then((text) => {
        unityContext.send("Features", "PasteText", text);
      });
    }
    if ((event.ctrlKey || event.metaKey) && charCode === "c") {
      unityContext.send("Features", "CopySelectedText");
    }
  };

  return (
    <Router>
      <MsalProvider instance={instance}>
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          errorComponent={(error) => <p>An Error Occurred: {error}</p>}
          loadingComponent={() => <p>Authentication in progress...</p>}
          authenticationRequest={loginRequest}
        >
          <div onKeyDown={handleKeyDown} contentEditable={true}>
            {progress < 1 ? <ProgressOverlay progress /> : null}
            <Dropzone
              unityContext={unityContext}
              instance={instance}
              setIsUploading={setIsUploading.bind(this)}
            />
            <ThumbnailDropzone unityContext={unityContext} />
            <VideoDropzone unityContext={unityContext} />
            <Unity
              unityContext={unityContext}
              style={{
                height: "100%",
                width: "100%",
                position: "absolute",
              }}
            />
            {isUploading ? <Spinner /> : null}
          </div>
        </MsalAuthenticationTemplate>
      </MsalProvider>
    </Router>
  );
};

export default App;
