import { CircularProgress } from "@material-ui/core";

const Spinner = () => {
  return (
    <div>
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
          display: "flex",
          position: "absolute",
        }}
      >
        <CircularProgress />
      </div>
    </div>
  );
};

export default Spinner;
